import lozad from 'lozad'

const lazyVideo = () => {
    function lazy() {
        const video = document.querySelector('.lozad');
        const observer = lozad(video); 
        observer.observe();
        }
    lazy()
}

export default lazyVideo