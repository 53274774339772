
import menu from './modules/menu'
import lazyVideo  from './modules/lazy-video'
import swiper from './slider'
import lazyImg from './modules/lazy-img'





window.addEventListener('DOMContentLoaded', () => {
    menu()
    lazyVideo()
    lazyImg()
    lightGallery(document.getElementById('gallery'), {
        download: false,
        share: false,
        selector: '.works__gallery--item',
      });

      swiper

    
      
      document.querySelectorAll('a[href^="#"')
      .forEach(link => {
          link.addEventListener('click', function(e) {
              e.preventDefault();
              let href = this.getAttribute('href').substring(1);

              const scrollTarget = document.getElementById(href)
                console.log(scrollTarget)
              const topOffset = 100

              const elementPosition = scrollTarget.getBoundingClientRect().top
              const offsetPosition = elementPosition - topOffset

              window.scrollBy({
                top: offsetPosition,
                behavior: 'smooth'
              })
          })
      })
      
      
      const triggers = Array.from(document.querySelectorAll('[data-toggle="collapse"]'));

window.addEventListener('click', (ev) => {
  const elm = ev.target;
  if (triggers.includes(elm)) {
    const selector = elm.getAttribute('data-target');
    collapse(selector, 'toggle');
  }
}, false);


const fnmap = {
  'toggle': 'toggle',
  'show': 'add',
  'hide': 'remove'
};
const collapse = (selector, cmd) => {
  const targets = Array.from(document.querySelectorAll(selector));
  targets.forEach(target => {
    target.classList[fnmap[cmd]]('show');
  });
}
      
});



    
    
